<template>
  <MaximizedDialog
    :model-value="modelValue"
    :title="t('Change Storage')"
    transition-show="slide-up"
    transition-hide="slide-down"
    @close="handleClose"
  >
    <PrimaryErrorBanner />
    <HorizontalPropsList v-slot="{ item }">
      <component
        :is="item"
        :caption="t('Operation ID')"
      >
        {{ store.transfer.id }}
      </component>
      <component
        :is="item"
        :caption="t('Current Storage')"
        class="text-no-wrap"
      >
        {{ store.storageTo.name }}
      </component>
    </HorizontalPropsList>

    <div class="q-pa-lg col column justify-center">
      <div class="column justify-center">
        <GraphQLQueryScanField
          :query="scanQuery"
          :variables="{ transferId: store.transfer?.id }"
          :disabled="changingTargetStorage"
          :rules="scanRules"
          :hint="t('Scan Second Storage')"
          :placeholder="t('Storage')"
          :not-found-message="t('Storage not found')"
          append-icon="mdi-inbox-arrow-down"
          @found="handleFoundStorageTo"
          @scan="handleScanStorageTo"
        />
      </div>
    </div>

    <template #bottom>
      <ButtonsRow
        v-slot="{ buttonProps }"
        v2
      >
        <QBtn
          v-bind="buttonProps"
          icon="mdi-close-circle-outline"
          @click="emit('update:modelValue', false)"
        >
          {{ t('Cancel') }}
        </QBtn>
      </ButtonsRow>
    </template>
  </MaximizedDialog>
</template>

<script setup lang="ts">

import MaximizedDialog from '@/components/MaximizedDialog.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import useTransferProcessStore from '@/stores/transferProcess';
import HorizontalPropsList from '@/views/Mobile/HorizontalPropsList.vue';
import { useI18n } from 'vue-i18n';
import GraphQLQueryScanField from '@/components/Mobile/GraphQLQueryScanField.vue';
import { gql, useMutation } from '@urql/vue';
import StorageForTransfer from '@/graphql/fragments/StorageForTransfer';
import StorageUnitForTransfer from '@/graphql/fragments/StorageUnitForTransfer';
import StockForTransfer from '@/graphql/fragments/StockForTransfer';
import type { MutationChangeTargetStorageArgs, Stock, Storage, TransferItem } from '@/graphql/types';
import useSpeaker from '@/composables/useSpeaker';
import TransferItemForTransfer from '@/graphql/fragments/TransferItemForTransfer';
import useErrorHandling from '@/composables/useErrorHandling';

const store = useTransferProcessStore();

const { t } = useI18n();

defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'changed'): void;
}>();

const {
  fillErrorsFromGraphQLError,
  clearErrors,
  PrimaryErrorBanner,
} = useErrorHandling();

function handleClose() {
  clearErrors();
  emit('update:modelValue', false);
}

const scanQuery = gql`
  ${StorageForTransfer}
  ${StorageUnitForTransfer}
  ${StockForTransfer}
  query GetSecondStorageByBarcodeForTransfer($transferId: ID!, $barcode: String!) {
    storageByBarcode(barcode: $barcode) {
      ...StorageForTransfer
    }
    stocksByStorageBarcode(transferId: $transferId, barcode: $barcode) {
      ...StockForTransfer
    }
  }
`;

function handleFoundStorageTo(data: {
  storageByBarcode: Storage | null;
  stocksByStorageBarcode: Stock[];
}): void {
  if (!data.storageByBarcode) {
    return;
  }
  store.stocksInStorageTo = data.stocksByStorageBarcode;
}

const speaker = useSpeaker();

const scanRules = [
  notEqualToStorageFromRule,
  notEqualToStorageToRule,
  store.validation.storageToCellTypeRule,
  store.validation.storageToContainerTypeRule,
  store.validation.storageFromCellTypeRule,
  store.validation.storageFromContainerTypeRule,
];

async function handleScanStorageTo(storage: Storage) {
  clearErrors();
  speaker.speak(t('To {name}', { name: storage.name }));
  let newTransferItem = null;
  if (store.transfer?.task && store.selectedItem) {
    const { data, error } = await changeTargetStorage(
      { transferId: store.transfer.id, targetStorageToId: storage.id, transferTaskItemId: store.selectedItem.id },
    );

    if (error) {
      fillErrorsFromGraphQLError(error);
      return;
    }

    const selectedTransferItem = store.transfer.items.find(i => i.id === store.selectedItem.id)!;

    if (selectedTransferItem.transferredAmount === 0) {
      const index = store.transfer.items.indexOf(selectedTransferItem!);
      store.transfer.items.splice(index, 1);
    } else {
      selectedTransferItem.plannedAmount = selectedTransferItem.transferredAmount;
    }

    newTransferItem          = data!.transferItem;
    const existingTargetItem = store.transfer.items.find(i => i.id === newTransferItem!.id);

    if (existingTargetItem) {
      existingTargetItem.plannedAmount = newTransferItem.plannedAmount;
    } else {
      store.transfer.items.push(newTransferItem);
    }
  }

  store.availableStorages.push(storage);
  store.storageTo = storage;
  store.updateTransferState();

  if (newTransferItem) {
    store.itemIdSelectedOnReset = newTransferItem.id;
  }

  emit('update:modelValue', false);
  emit('changed');
}

const {
  executeMutation: changeTargetStorage,
  fetching:        changingTargetStorage,
} = useMutation<{ transferItem: TransferItem  }, MutationChangeTargetStorageArgs>(gql`
  mutation TransferChangeTargetStorage($transferId: ID!, $targetStorageToId: ID!, $transferTaskItemId: ID!) {
    transferItem: changeTargetStorage(transferId: $transferId, targetStorageToId: $targetStorageToId, transferTaskItemId: $transferTaskItemId) {
      ...TransferItemForTransfer
    }
    ${TransferItemForTransfer}
  }
`);

function notEqualToStorageFromRule(storageTo: Storage) {
  if (storageTo.id === store.storageFrom!.id) {
    return t('The new Storage must be different from the Storage From');
  }

  return true;
}
function notEqualToStorageToRule(storageTo: Storage) {
  if (storageTo.id === store.storageTo!.id) {
    return t('The new Storage is the same as the current one');
  }

  return true;
}

</script>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/speaking.yaml"></i18n>

<i18n lang="yaml">
ru:
  Change Storage: Изменить ячейку
  Current Storage: Текущая ячейка
  Operation ID: Номер операции
  The new Storage must be different from the Storage From: Новое место хранения должно отличаться от места хранения Откуда
  The new Storage is the same as the current one: Новое место хранения не отличается от текущего

en:
  Change Storage: Change Storage
  Current Storage: Current Storage
  Operation ID: Operation ID
  The new Storage must be different from the Storage From: The new Storage must be different from the Storage From
  The new Storage is the same as the current one: The new storage is the same as the current one

</i18n>
