<template>
  <FilterableTable
    :fields="headers"
    :rows="items"
    :row-is-deleted="m => m.storable.productPack.deletedAt !== null"
    :item-meets-search-string="movementMeetsSearchString"
    :storage-prefix="'transferCard.' + transfer.id"
    show-line-numbers
    no-loading-columns
  >
    <template #body-cell-sku="column">
      <BodyCellLink
        :column="column"
        :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: column.row.storable.productPack.product.id } }"
        variant="link"
      />
    </template>
    <template #body-cell-repacking="column">
      <QTd :props="column">
        <div
          v-for="([from, to]) in column.value"
          :key="`${from} -> ${to}`"
        >
          {{ from }}
          <QIcon name="mdi-arrow-right" />
          {{ to }}
        </div>
      </QTd>
    </template>
  </FilterableTable>
</template>

<script setup lang="ts">

import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import FilterableTable from '@/components/FilterableTable.vue';
import type { Movement, Transfer, Unpacking } from '@/graphql/types';
import itemAmount from '@/helpers/itemAmount';
import movementMeetsSearchString from '@/helpers/movementMeetsSearchString';
import ROUTES from '@/router/routeNames';
import type { QTableColumn } from 'quasar';
import { ascend, collectBy, sortWith } from 'ramda';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  transfer: Transfer;
}>();

const items = computed(() => sortWith([
  ascend(m => m.storageFrom?.name ?? ''),
  ascend(m => m.storageTo?.name ?? ''),
  ascend(m => m.storable.productPack.product.sku),
], collectBy(
  m => `${m.storageFrom?.id}_${m.storageTo?.id}_${m.storable.productPack.id}`,
  props.transfer.movements,
).map(group => ({
  ...group[0],
  amount: group.reduce((prev, m) => prev + m.amount, 0),
}))));

const headers: QTableColumn<Movement>[] = [
  {
    label: t('SKU'),
    name:  'sku',
    field: m => m.storable.productPack.product.sku,
    align: 'left',
  },
  {
    label: t('Name'),
    name:  'productPack.product.name',
    field: m => m.storable.productPack.product.name,
    align: 'left',
  },
  {
    label: t('Amount'),
    name:  'amount',
    field: m => itemAmount({ amount: m.amount, productPack: m.storable.productPack }),
  },
  {
    label: t('Origin Cell/Container'),
    name:  'storageFrom',
    field: m => m.storageFrom?.name ?? '',
  },
  {
    label: t('Target Cell/Container'),
    name:  'storageTo',
    field: m => m.storageTo?.name ?? '',
  },
  {
    label: t('Repacking'),
    name: 'repacking',
    field: m => {
      const repackingMovements = props.transfer.childOperations
        .filter(o => (o as Unpacking).__typename === 'Unpacking')
        .flatMap(op => op.movements);

      const repackingMovementsPairs = repackingMovements
        .filter(rm => rm.storageFrom && !rm.storageTo && rm.storageFrom.id === m.storageTo!.id && rm.storable.id === m.storable.id)
        .map(m => [m, repackingMovements.find(rm => rm.complementaryMovement?.id === m.id)!] as const);

      return repackingMovementsPairs.map(([mvFrom, mvTo]) => [
        `${mvFrom.amount} ${mvFrom.storable.productPack.measurementUnit.shortName}`,
        `${mvTo.amount} ${mvTo.storable.productPack.measurementUnit.shortName}`,
      ]);
    },
  },
];

</script>
