<template>
  <BigLoading v-if="store.initializing" />
  <QPage
    v-else
    class="column no-wrap scroll-y"
  >
    <TransferTaskDialog
      v-model="showingTask"
      :transfer="store.transfer!"
      items-selectable
      @item-selected="selectedPlanItem = $event"
    >
      <template #product-count>
        {{ alreadyScannedAmount }} / {{ transferredAmount }}
      </template>
      <template #buttons="{ buttonProps, storageFrom, storageTo }">
        <QBtn
          v-if="canChangeStorage(storageFrom, storageTo)"
          v-bind="buttonProps"
          icon="mdi-check"
          @click="changingStorages = [storageFrom, storageTo]"
        >
          {{ t('Make Current') }}
        </QBtn>
      </template>
    </TransferTaskDialog>
    <TransferHeader />
    <TransferScanStorages
      v-if="!store.storageFrom || !store.storageTo"
      @cancel="cancel"
      @show-task="showingTask = true"
      @show-history="historyDialog = true"
    />
    <TransferScanStorable
      v-else
      v-model:carousel-input-disabled="carouselInputDisabled"
      class="col full-width"
      @cancel="cancel"
      @show-task="showingTask = true"
      @show-history="historyDialog = true"
      @change-storage="changingStorages = $event"
    />
    <PrimaryErrorBanner />
    <TransferChangeStorages
      :expected-storages="changingStorages"
      @scan="handleStorageChange"
      @close="changingStorages = null"
    />
    <MaximizedDialog
      v-model="historyDialog"
      transition-show="slide-up"
      transition-hide="slide-down"
      :title="breadcrumbs.join(' / ')"
      @close="historyDialog = false"
    >
      <TransferDetails
        :transfer="store.transfer!"
        hide-header
      />
    </MaximizedDialog>
  </QPage>
</template>

<script setup lang="ts">
import BigLoading from '@/components/BigLoading.vue';
import MaximizedDialog from '@/components/MaximizedDialog.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useErrorHandling from '@/composables/useErrorHandling';
import useWakeLockWhenMounted from '@/composables/useWakeLockWhenMounted';
import ROUTES from '@/router/routeNames';
import useTransferProcessStore from '@/stores/transferProcess';
import TransferChangeStorages from '@/views/Mobile/Transfer/TransferChangeStorages.vue';
import TransferDetails from '@/views/Mobile/Transfer/TransferDetails.vue';
import TransferHeader from '@/views/Mobile/Transfer/TransferHeader.vue';
import TransferScanStorages from '@/views/Mobile/Transfer/TransferScanStorages.vue';
import TransferScanStorable from '@/views/Mobile/Transfer/TransferScanStorable.vue';
import TransferTaskDialog from '@/views/Mobile/Transfer/TransferTaskDialog.vue';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import * as R from 'ramda';
import type { Cell, Container, Storage, TransferItem } from '@/graphql/types';
import navigateBack from '@/helpers/navigateBack';

useWakeLockWhenMounted();

const store = useTransferProcessStore();

const { t } = useI18n();

const { breadcrumbs } = useBreadcrumbs(computed(() => t('operationType.Transfer')));

const {
  fillErrorsFromGraphQLError,
  clearErrors,
  PrimaryErrorBanner,
} = useErrorHandling();

const carouselInputDisabled = ref<boolean>(false);

const router = useRouter();

const props = withDefaults(defineProps<{
  transferId?: string | null;
}>(), {
  transferId: null,
});

const showingTask = ref(false);

watch(showingTask, value => {
  if (!value && selectedPlanItem.value) {
    store.selectItemOnCarouselById(selectedPlanItem.value.id);
  }
});

const alreadyScannedAmount = computed(() => R.sum(
  (store.transfer?.movements ?? [])
    .map(m => m.storable.productPack.quantityInMinMeasurementUnits * m.amount)),
);

const transferredAmount = computed(() => R.sum(store.transfer!.items.map(
  i => i.storageUnit.productPack.quantityInMinMeasurementUnits * i.transferredAmount,
)));

const selectedPlanItem = ref<TransferItem | null>(null);

const historyDialog = ref(false);

onBeforeMount(async () => {
  await store.init(props.transferId);
});

watch(() => store.transfer, transfer => {
  if (!props.transferId && transfer?.id) {
    router.replace({ name: ROUTES.TRANSFER_PROCESS, params: { transferId: transfer.id } });
  }
});

const changingStorages = ref<[Storage, Storage] | null>(null);

function handleStorageChange([storageFrom, storageTo]: [Storage, Storage]): void {
  changingStorages.value = null;
  store.storageFrom = storageFrom as Container | Cell;
  store.storageTo = storageTo as Container | Cell;
  showingTask.value = false;
}

function canChangeStorage(storageFrom: Cell | Container, storageTo: Cell | Container): boolean {
  return store.storageFrom?.id !== storageFrom.id || store.storageTo?.id !== storageTo.id;
}

async function cancel() {
  clearErrors();

  const { error } = await store.cancelTransfer();

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  store.clearStateForTransfer();

  await navigateBack({ name: ROUTES.TRANSFER_DASHBOARD });

  store.transfer = null;
  store.storageFrom = null;
  store.storageTo = null;
}

</script>
